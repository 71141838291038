import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSpeechSynthesis } from "react-speech-kit";
import { io } from "socket.io-client";
import Topbar from "../../../components/Topbar";
import { socketBase, socketPath } from "../../../urlConfig";
import { getAllSkippedCall, getAllQueueProcess } from "../../../actions";
import { maxLimit, queueProcessConstants } from "../../../actions/constants";
import ".././index.css";

const socket = io(socketBase, { path: socketPath }).connect();

export default function QueueAdmissionDisplay() {
  const { machineId } = useParams();

  const { tvCounter, tvDisplayMissed } = maxLimit

  // Room State
  const [room, setRoom] = useState(null);

  // Queues State
  let { queueProcessSkipped, allQueueProcess } = useSelector((state) => state.queueProcess);

  const synth = window?.speechSynthesis;
  const voice = window?.speechSynthesis?.getVoices();
  const indonesianVoice = voice.find((voice) => voice.lang.includes('id-ID'));
  const utterance = new SpeechSynthesisUtterance();
  // set to indonesian voice
  utterance.voice = indonesianVoice;
  utterance.pitch = 1;
  utterance.rate = 1;
  utterance.lang = 'id-ID'

  const dispatch = useDispatch();

  useEffect(() => {
    setRoom(machineId);
    dispatch(getAllQueueProcess(machineId)).catch((err) => console.log(err));
    dispatch(getAllSkippedCall(machineId, tvDisplayMissed)).catch((err) => console.log(err));
  }, [dispatch, machineId, tvDisplayMissed]);

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

  useEffect(() => {
    socket.on("updateReduxAdmissionDisplay", (newData) => {
      dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS,
        payload: newData.process,
      });
      utterance.text = newData.text
      synth.speak(utterance)
      dispatch(getAllQueueProcess(machineId)).catch((err) => console.log(err));
      dispatch(getAllSkippedCall(machineId, tvDisplayMissed)).catch((err) => console.log(err));
    })

    socket.on("receive_message", (data) => {
      utterance.text = data.text
      synth.speak(utterance)
      dispatch(getAllQueueProcess(machineId)).catch((err) => console.log(err));
      dispatch(getAllSkippedCall(machineId, tvDisplayMissed)).catch((err) => console.log(err));
    })


    return () => {
      socket.off("updateReduxAdmissionDisplay");
      socket.off("receive_message");
    }
  }, [socket]);

  return (
    <div className="queue-bg">
      <div className="queue-container">
        <div>
          <Topbar />
        </div>
        <Row className="m-0 justify-content-center w-100">
          <Row className="fw-bold fs-1 px-5">Antrian Rawat Jalan</Row>
          <Row>
            <Row className="fw-bold fs-2 mx-0">
              <div className="text-center">SEDANG DALAM ANTRIAN</div>
            </Row>
            <Row className="mx-0">
              {allQueueProcess && allQueueProcess.length > 0 ? (
                allQueueProcess.slice(0, tvCounter).map((data, index) => {
                  return <Col className="m-3" key={index}>
                    <Row className="text-center rounded-top bg-black text-white fs-2 fw-semibold py-2">
                      <div>{data?.counter}</div>
                    </Row>
                    <Row className="bg-white rounded-bottom fw-bold">
                      <div className="queue-word d-flex align-items-center justify-content-center">
                        {data?.queuePrefixNumber}
                      </div>
                    </Row>
                  </Col>
                }

                )
              ) : (
                <Col className="mx-3">
                  <Row className="bg-white rounded-bottom fw-bold">
                    <div className="queue-word d-flex align-items-center justify-content-center">
                      Belum ada antrian
                    </div>
                  </Row>
                </Col>
              )}
            </Row>

            <Row>
              <div className="text-center fw-bold fs-2 mb-4">
                ANTRIAN TERLEWATKAN
              </div>
              <Container>
                <Row>
                  {queueProcessSkipped?.slice(0, tvDisplayMissed).map((data, index) => (
                    <Col key={index} xs={2} >
                      <div className="box-down rounded fw-bold text-center">{data.queuePrefixNumber}</div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Row>
          </Row>
        </Row>
      </div>
    </div>
  );
}